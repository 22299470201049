<template>
  <v-dialog
      max-width="60%"
      v-model="dialog"
      @input="get_court_type"
  >

    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}" :loading="loading">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">{{ tooltip_text }}</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="non_field" name="None Field"
                                    rules=""
                                    v-slot="{ errors }">
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="name" name="Name" rules="required|max:512"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Name*"
                      hint="Please enter unique name only"
                      required
                      autocomplete="nope"
                      v-model="item.name"
                      maxlength="512"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="type" name="Type"
                                    rules="objectNotEmpty:type|required"
                                    v-slot="{ errors }">
                  <v-autocomplete
                      label="Court Type*"
                      hint="Select a Court Type"
                      :items="court_type"
                      item-text="name"
                      item-value="id"
                      v-model="item.court_type"
                      required
                      autocomplete="nope"
                      :loading="court_type_loading"
                  >
                  </v-autocomplete>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="code" name="Code"
                                    rules="required|min_value:1"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Code*"
                      hint="Code no for internal use do not change"
                      required
                      autocomplete="nope"
                      v-model="item.code"
                      maxlength="32"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"
import app_setting_api from "@/constants/app_setting/app_setting_api";

export default {
  name: "add_edit_form",
  props: ['passed_item', 'icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn', 'url', 'snackbar_text', 'tooltip_text', 'loading'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    court_type_model: {
      get() {
        if (this.passed_item) {
          return JSON.parse(JSON.stringify(this.passed_item)).type
        }
        return this.item.type
      },
      set(val) {
        if (this.passed_item) {
          this.item.type = JSON.parse(JSON.stringify(this.passed_item))
        }
        this.item.type = val
      }
    }
  },
  data: () => ({
    submit_loading: false,
    court_type_loading: true,
    dialog: false,
    item: {
      id: null,
      name: null,
      court_type: {
        // id: null,
        // name: null
      },
      code: null
    },
    court_type: [{name: ""}],
  }),
  methods: {
    async add_new_item() {
      this.axios_conf
      let data = JSON.parse(JSON.stringify(this.item))
      await axios.post(this.url, data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.$refs.vform.reset()
            this.$refs.vform.resetValidation()
            this.dialog = false
            this.$emit('form_status', true)
            this.$store.commit("update_snackbar", {
              text: this.snackbar_text.replace('{value}', data.name),
              status: true
            })
          })
          .catch((error) => {
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              this.$refs.observer.setErrors({
                name: error.response.data.errors.name,
                type: error.response.data.errors.type,
                non_field: error.response.data.errors.non_field_errors,
              });
            }
          })
      this.submit_loading = false
    },
    async edit_item() {
      this.axios_conf
      let data = {
        ...this.item,
      }
      if (typeof (data.court_type) === "object") {
        data.court_type = data.court_type.id
      }
      await axios.patch(this.url.replace('{id}', this.passed_item.id), data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.non_field_errors = []
            this.$emit('form_status', true)
            this.$store.commit("update_snackbar", {text: this.snackbar_text, status: true})
          })
          .catch((error) => {
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              this.$refs.observer.setErrors({
                name: error.response.data.errors.name,
                type: error.response.data.errors.type,
                non_field: error.response.data.errors.non_field_errors,
              });
            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      if (this.action === 'add') {
        this.add_new_item()
      } else if (this.action === 'edit') {
        this.edit_item()
      }
    },
    get_court_type() {
      this.axios_conf
      let config = {
        headers: {multi: true}
      }
      axios.get(app_setting_api.court_type.list, config).then((resp) => {
        this.court_type = resp.data.items
        this.$store.commit('setJWT', resp.data.key)
        this.court_type_loading = false
      }).catch((err) => {
        // console.log(err)
        this.$store.commit("update_snackbar", {text: `Error Occurred #${err}`, status: true})
      })
    }
  },
  watch: {},
  mounted() {
    if (this.passed_item) {
      this.item = JSON.parse(JSON.stringify(this.passed_item))
    }
  }
}
</script>

<style scoped>
</style>