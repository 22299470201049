<template>
  <v-container>
    <CaseTypeList/>
  </v-container>
</template>

<script>
import CaseTypeList from "@/components/app_setting/case_type/CaseTypeList";

export default {
  name: "CaseTypePage",
  components: {
    CaseTypeList,
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Court Types'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>